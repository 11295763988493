import React from "react";
import Events from "../subpage_src/components/Events";
import data from '../data/Data';
import Layout from "../subpage_src/components/Layout";

const EventsPage = () => {
    return (
        <Layout lang={"en"}>
            <Events
                lang={"en"}
                events = {data.events}
            />
        </Layout>
    )

}

export default EventsPage;